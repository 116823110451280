/**
  * название функции
  *
  * @param {number} first - первое число
  * @returns {number}
  */
$('.burger__menu').click(function(){
  //всякие проверки
  $('.mobile__nav-open').slideDown();
})

$('.close__icon').click(function(){
  //всякие проверки
  $('.mobile__nav-open').hide();
})

  $(".articles__inner").slick({
   slidesToShow: 3,
   infinite:true,
   slidesToScroll: 1,
   autoplaySpeed: 2000,
     // dots: false, Boolean
    // arrows: false, Boolean

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToShow: 1,
        arrows: false,
        centerMode: true,
        infinite: true,
        variableWidth: true
      }
    }
  ]
  });

$(function() {
  
  //BEGIN
  $(".js-faq-title").on("click", function(e) {

    e.preventDefault();
    var $this = $(this);

    if (!$this.hasClass("faq__active")) {
      $(".js-faq-content").slideUp(800);
      $(".js-faq-title").removeClass("faq__active");
      $('.js-faq-rotate').removeClass('faq__rotate');
    }

    $this.toggleClass("faq__active");
    $this.next().slideToggle();
    $('.js-faq-rotate',this).toggleClass('faq__rotate');
  });
  //END
  
});
// Image Slider Demo:
// https://codepen.io/vone8/pen/gOajmOo